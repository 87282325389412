import React, { useCallback, useContext, useEffect, useState } from 'react'
import MessengerDrawer from '../features/views/Chat'
import ListView from '../features/views/Threads'
import NewThreadView from '../features/views/NewThread'
import { viewContext } from '../context/viewContext'
import { VIEWS } from '../features/views/views'
import { apiSlice, useGetInitialLoadQuery } from '../features/api/apiSlice'
import { userContext } from '../context/userContext'
import { useDispatch } from 'react-redux'
import { usePaginate } from '../common/usePaginate'

const MessengerWrapper = ({ page, setPage, setTotal, PaginationComponent }) => {
  const dispatch = useDispatch()
  const { currentView, unique_owner_id, threadsPerPage } = useContext(viewContext)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [activeThreadId, setActiveThreadId] = useState(null)

  const handleClose = useCallback(() => {
    if (shouldRefetch) {
      dispatch(apiSlice.endpoints.getInitialLoad.initiate({ unique_owner_ids: [unique_owner_id], per_page: threadsPerPage, page }, { subscribe: false, forceRefetch: true }))
    }
    setShouldRefetch(false)
    setDrawerOpen(false)
    setActiveThreadId(null)
  }, [shouldRefetch, unique_owner_id])

  return (
    <div className='messenger-wrapper'>
      <div className='dome-relative'>
        {currentView === VIEWS.LIST_VIEW && <MessengerDrawer activeThreadId={activeThreadId} drawerOpen={drawerOpen} onClose={handleClose} shouldRefetch={shouldRefetch} setShouldRefetch={setShouldRefetch} threadPage={page} />}
        <div className={`messenger dome-light-shadow dome-rounded-border dome-full-width ${drawerOpen ? 'open' : ''}`}>
          {currentView === VIEWS.LIST_VIEW && <ListView setActiveThreadId={setActiveThreadId} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} page={page} setTotal={setTotal} PaginationComponent={PaginationComponent} />}
          {currentView === VIEWS.NEW_MESSAGE_VIEW && <NewThreadView page={page} />}
        </div>
      </div>
    </div>
  )
}

const MessengerToggle = ({ handleClick }) => {

  return (
    <div className='dome-d-flex dome-justify-end dome-align-center' style={{paddingInline: '17px'}} >
      <div onClick={handleClick}><i className='fal fa-comments dome-color-aqua' style={{fontSize: '18px', cursor: 'pointer'}}></i></div>
    </div>
  )
}

const App = () => {
  const { threadsPerPage } = useContext(viewContext)
  const [openMessenger, setOpenMessenger] = useState(false)
  const { page, setPage, setTotal, Component: PaginationComponent } = usePaginate({ perPage: threadsPerPage })

  const handleClick = useCallback(() => {
    setOpenMessenger(!openMessenger)
  }, [openMessenger])

  return (
    <div className='dome-relative'>
      <MessengerToggle handleClick={handleClick} page={page} />
      {openMessenger && <MessengerWrapper page={page} setPage={setPage} setTotal={setTotal} PaginationComponent={PaginationComponent} />}
    </div>
  )

}

export default App
