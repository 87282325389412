import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Portal } from 'components/utility/portal'
import InstantQuoteLookup from './index'

export default function InstantQuoteLookupOverlay({ buttonText, buttonClasses, currentUser }) {
  const [show, setShow] = useState(false)
  const overlayRef = useRef(null)

  function hideOverlay(e) {
    if (e.target !== overlayRef.current) {
      return
    }

    setShow(false)
  }

  useEffect(() => {
    document.body.classList.toggle('overlay-activated', show)
  }, [show])

  return (
    <>
      <button className={clsx(buttonClasses)} onClick={() => setShow(true)}>
        {buttonText}
      </button>
      <Portal>
        <div className={clsx('quote-overlay', show && 'show')}>
          <div
            className="quote-overlay-wrapper dome-d-flex dome-align-center dome-justify-center"
            onClick={hideOverlay}
            ref={overlayRef}
          >
            <InstantQuoteLookup currentUser={currentUser} visible={show} />
          </div>
        </div>
      </Portal>
    </>
  )
}
