import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import QuoteInterstitial from './quote_interstitial'
import {
  INSURANCE_QUOTE_STEPS,
  resetStateWithProperty as resetStateWithPropertyAction,
  initiateQuote as initiateQuoteAction,
  setStep as setStepAction,
  setPackageId as setPackageIdAction,
  setInsuranceType as setInsuranceTypeAction,
  getBuildingRequirements as getBuildingRequirementsAction,
  selectInsuranceQuoteInterstitialState,
} from './store/slice'
import { useDomecileInterstitial } from 'components/utility/domecile_interstitial/hooks'
import { InterstitialContext, InterstitialsWrapper } from 'components/utility/domecile_interstitial/wrapper'

function GetQuoteButton({
  building,
  package_id,
  constant_key,
  onInterstitialClose,
  onInterstitialOpen,
  started,
  insuranceType,
}) {
  const { show, open, close, hiding } = useDomecileInterstitial()

  const dispatch = useDispatch()
  const { fullScreenLoading, serverError, startingQuote } = useSelector(selectInsuranceQuoteInterstitialState)

  const openQuoteInterstitial = () => {
    open()
    if (typeof onInterstitialOpen === 'function') onInterstitialOpen()
  }

  const handleClose = () => {
    close()
    if (typeof onInterstitialClose === 'function') onInterstitialClose()
  }

  const populateInformation = useCallback(() => {
    dispatch(resetStateWithPropertyAction(building))
    dispatch(setPackageIdAction(package_id))
    dispatch(setInsuranceTypeAction(insuranceType))
    dispatch(setStepAction(INSURANCE_QUOTE_STEPS.policy_page))
  }, [dispatch, building, package_id, insuranceType])

  // FOR Instant Quote Lookup When building id is changed
  useEffect(() => {
    if (!building?.id) return

    populateInformation()
  }, [building?.id, populateInformation])

  // FOR Application Entrypoint: When the interstitial is hiding, reset the state with props information
  useEffect(() => {
    if (show || !building?.id) return

    populateInformation()
  }, [show, populateInformation])

  // FOR Instant Quote Lookup: When the interstitial is hiding, reset the state if building is cleared
  useEffect(() => {
    if (show) return

    if (building === null) {
      dispatch(resetStateWithPropertyAction(null))
    }
  }, [show, building === null, dispatch])

  useEffect(() => {
    if (fullScreenLoading.visible || !building?.id) return

    if (started) {
      dispatch(getBuildingRequirementsAction(building.id))

      // if (!startingQuote)
      //   dispatch(
      //     initiateQuoteAction({
      //       nextStep: INSURANCE_QUOTE_STEPS.policy_page,
      //     })
      //   )
    }
  }, [dispatch, started, building?.id])

  useEffect(() => {
    if (!building?.id) return

    if (started) {
      dispatch(getBuildingRequirementsAction(building.id))

      // if (!startingQuote)
      //   dispatch(
      //     initiateQuoteAction({
      //       nextStep: INSURANCE_QUOTE_STEPS.policy_page,
      //     })
      //   )
    }
  }, [dispatch, insuranceType])

  return (
    <>
      <button
        className={clsx('dome-btn dome-btn-base dome-btn-dark-green', serverError && 'dome-btn-disabled')}
        onClick={openQuoteInterstitial}
        disabled={serverError}
      >
        get quote
      </button>
      <InterstitialContext.Provider value={{ show, hiding }}>
        <QuoteInterstitial show={show} hiding={hiding} onClose={handleClose} constant_key={constant_key} />
      </InterstitialContext.Provider>
    </>
  )
}

GetQuoteButton.defaultProps = {
  constant_key: 'InsuranceConstants',
  started: true,
  insuranceType: 'homeowners',
}

export default (props) => (
  <InterstitialsWrapper>
    <GetQuoteButton {...props} />
  </InterstitialsWrapper>
)
