import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Header from '../../common/Header'
import { VIEWS } from './views'
import { format } from 'date-fns'

import { viewContext } from '../../context/viewContext'
import usePagination from './../../../../domeui/table/hooks/usePagination'

import { useGetInitialLoadQuery, sortedMessages, messageThreadsByOwnerId, selectThreadById, selectMessageAuthors, selectRoles, selectParticipants, selectOwner } from '../api/apiSlice'
import { userContext } from '../../context/userContext'
import { useDisplayParticipants } from '../../common/useDisplayParticipants'
import { usePaginate } from '../../common/usePaginate'

const NewButton = () => {
  const { setCurrentView } = useContext(viewContext)
  const handleClick = () => setCurrentView(VIEWS.NEW_MESSAGE_VIEW)

  return (
    <button onClick={handleClick} className='dome-btn dome-btn-base dome-btn-aqua-stroke'>+ new</button>
  )
}

const Thread = ({ threadId, setDrawerOpen, setActiveThreadId, isRead, page }) => {
  const { owner_id, unique_owner_id, threadsPerPage } = useContext(viewContext)
  const { users, thread, allMessages, messagesList, messageAuthors, roles, participantList, owner } = useGetInitialLoadQuery({unique_owner_ids: [unique_owner_id], page, per_page: threadsPerPage}, {
    selectFromResult: (result) => ({
      users: result.data?.users || {},
      thread: selectThreadById(result, threadId) || {},
      messageAuthors: selectMessageAuthors(result, threadId) || {},
      roles: selectRoles(result, owner_id) || {},
      allMessages: result.data?.messages || {},
      messagesList: sortedMessages(result, threadId),
      participantList: selectParticipants(result, threadId),
      owner: selectOwner(result, unique_owner_id)
    })
  })
  const firstMessage = allMessages[thread?.first_message_id]
  const lastMessage = allMessages[thread?.last_message_id]
  const lastUserPosted = users?.[lastMessage?.posted_by]
  const unreadCount = thread.unseen_count

  const messageAuthorType = messageAuthors[lastMessage.posted_by]
  const roleDisplay = roles[messageAuthorType]?.display_singular

  const totalMessages = thread.total_messages
  const replyCount = totalMessages ? totalMessages - 1 : 0
  const replyCountCopy = replyCount == 0 ? 'no replies yet' : `${replyCount} repl${replyCount > 1 ? 'ies' : 'y'}`

  const date = new Date(lastMessage?.posted_at || new Date().toISOString())
  const lastTimestamp = format(date, 'hh:mm aaa')

  const threadClasses = ['thread-container', 'dome-d-flex', 'dome-flex-column', 'dome-gap6', 'dome-rounded-border']
  threadClasses.push(isRead ? 'dome-bg-white' : 'dome-bg-fill')

  const handleClick = () => {
    setDrawerOpen(true)
    setActiveThreadId(threadId)
  }

  const pseudoLink = useMemo(() => {
    if (thread.can_post) {
      return <>
        <i className='fal fa-reply' />
        <span>reply</span>
      </>
    }

    return <span>{`open >`}</span>
  }, [thread])



  const participants = useDisplayParticipants(participantList, users, owner)

  if (!messagesList) return

  return (
    <div onClick={handleClick} className={threadClasses.join(' ')}>
      <div className="thread-header dome-d-flex dome-justify-between dome-align-center">
        <div className="dome-d-flex dome-align-center dome-gap12">
          <div className="dome-p2 dome-text-w500">{lastUserPosted?.name} <span className='role-display dome-color-med-grey'>{roleDisplay}</span></div>
          {unreadCount != 0 && <div className='dome-color-aqua pill-badge'>{unreadCount}</div>}
        </div>
        <div className='dome-p3'>{participants}</div>
      </div>
      <div className="thread-body">
        {lastMessage?.body !== firstMessage?.body ? <>
          <div className="recent-message dome-p1-lt">{lastMessage?.body}</div>
          <div className='original-message dome-p3 dome-color-lite-grey'>{firstMessage?.body}</div>
        </> : <div className="recent-message dome-p1-lt">{lastMessage?.body}</div>}
      </div>
      <div className="thread-footer">
        <div className="dome-d-flex dome-justify-between dome-align-center">
          <div className="dome-d-flex dome-align-center dome-gap18 dome-p3">
            <div>{lastTimestamp}</div>
            <div>
              {replyCountCopy}
            </div>
          </div>
          <div className="reply-pseudo-link dome-d-flex dome-align-center dome-gap3">
            {pseudoLink}
          </div>
        </div>
      </div>
    </div>
  )
}

const NoThreads = ({ isFetching }) => {
  return (
    <div style={{opacity: isFetching ? '.5' : '1'}}>
      <div className='thread-container no-messages' style={{paddingBlock: 0}}>
        <div className='dome-p1-lt'>no messages - create a new message using the button above.</div>
      </div>
    </div>
  )
}


const ThreadList = ({ threadsList, setActiveThreadId, setDrawerOpen, isFetching, page }) => {
  const { threadsPerPage } = useContext(viewContext)

  if (!threadsList || threadsList && !threadsList.length) {
    return <NoThreads isFetching={isFetching} />
  }

  return (
    <div style={{opacity: isFetching ? '.5' : '1'}}>
      {threadsList.map((thread, index) => {
        const isRead = thread.last_message_id == thread.last_seen_id
        return (
          <Thread
            key={index}
            threadId={thread.id}
            setActiveThreadId={setActiveThreadId}
            setDrawerOpen={setDrawerOpen}
            isRead={isRead}
            page={page}
          />
        )
      })}

    </div>
  )
}

const ThreadListContainer = ({ setActiveThreadId, setDrawerOpen, page, setTotal, PaginationComponent }) => {
  const { owner_id, unique_owner_id, threadsPerPage } = useContext(viewContext)
  const { threadsList, pagination, error, isLoading, isFetching } = useGetInitialLoadQuery({ unique_owner_ids: [unique_owner_id], page, per_page: threadsPerPage }, {
    selectFromResult: (result) => ({
      ...result,
      pagination: result.data?.pagination,
      threadsList: messageThreadsByOwnerId(result, owner_id)
    })
  })

  useEffect(() => {
    if (pagination) {
      setTotal(pagination.total_count)
    }
  }, [pagination])

  if (isLoading) {
    return <div style={{paddingInline: '12px'}}>fetching messages... </div>
  }

  return <>
    <ThreadList threadsList={threadsList} setActiveThreadId={setActiveThreadId} setDrawerOpen={setDrawerOpen} isFetching={isFetching} page={page} />
    {(pagination && pagination.total_count > threadsPerPage) && <PaginationComponent />}
  </>
}

const ListView = ({ setActiveThreadId, drawerOpen, setDrawerOpen, page, setTotal, PaginationComponent }) => {
  const {currentView} = useContext(viewContext)

  if (currentView !== VIEWS.LIST_VIEW) return

  return (
    <div className='dome-d-flex dome-flex-column dome-gap12'>
      <Header headerTitle='messaging'>
        <NewButton />
      </Header>
      <ThreadListContainer setActiveThreadId={setActiveThreadId} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} page={page} setTotal={setTotal} PaginationComponent={PaginationComponent} />
    </div>
  )
}

export default ListView
