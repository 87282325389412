import { useCallback, useMemo, useRef, useState, useEffect, useLayoutEffect } from "react";

export function useWatchableRef(initialValue = null) {
  const ref = useRef(initialValue);
  const [version, setRefVersion] = useState(null);

  const handleRef = useCallback((node) => {
    ref.current = node;
    setRefVersion(Math.random());
  }, []);

  return useMemo(
    () => ({
      ref: handleRef,
      current: ref.current,
      version,
    }),
    [handleRef, version]
  );
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
