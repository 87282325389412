import React, { useEffect, useMemo, useState } from 'react'
import { Provider, useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { isEmpty, isFinite } from 'lodash-es'

import { store } from '../store/store'
import { selectInsuranceQuoteInterstitialState, setCurrentUser, setStartingQuote } from '../store/slice'

import GetQuoteButton from '../button'
import Switch from 'components/utility/switch'
import { formattedMoney } from '../utils'
import { useGetStartingQuote } from '../hooks'
import viaffinityLogo from '/app/assets/images/insurance/logos/viafinity.png'
import chubbLogo from '/app/assets/images/insurance/logos/chubb.png'
import loadingIcon from '/app/assets/images/insurance/icons/loader.gif'
import MonetaryAmount from '../components/MonetaryAmount'

const ServerError = () => {
  return (
    <div className="quote-server-error">
      <div className="dome-p2 dome-color-dark-grey">The Chubb rating service is unavailable.</div>
      <div className="dome-p3-lt dome-color-dark-grey" style={{ fontSize: '10px' }}>
        If this problem persists, please contact{' '}
        <a href="mailto:info@domecile.com" className="dome-link dome-link-aqua" style={{ fontSize: '10px' }}>
          info@domecile.com
        </a>
        .
      </div>
    </div>
  )
}

const QuoteLoading = () => {
  return (
    <div className="quote-loading dome-d-flex dome-align-center">
      <div className="dome-loading-img">
        <img src={loadingIcon} width={95} height={95} />
      </div>
      <div>
        <div className="dome-p2 dome-color-dark-grey">We’re still working on the estimate for this property.</div>
        <div className="dome-p3-lt dome-color-dark-grey">In the meantime, continue to the next step.</div>
      </div>
    </div>
  )
}

const QuoteResult = ({ premium }) => {
  const parsedPremium = parseInt(premium)
  const monthlyAmount = (isFinite(parsedPremium) ? parsedPremium : 0) / 12
  const formattedMonthlyAmount = useMemo(() => {
    return formattedMoney(monthlyAmount)
  }, [monthlyAmount])

  return (
    <div>
      <div className="dome-p3 dome-color-med-grey">quote estimate:</div>
      <div className="dome-p1 dome-d-flex dome-align-baseline">
        <div className="dome-h2 dome-color-dark-green dome-d-flex dome-align-start" style={{lineHeight: 1}}>
          <MonetaryAmount amount={monthlyAmount} />
        </div>
        <span>/month</span>
      </div>
    </div>
  )
}

function EstimatedQuoteResult({ building, package_id, expanded, closeExpansion, insuranceType: defaultInsuranceType }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [insuranceType, setInsuranceType] = useState(defaultInsuranceType)
  const { quote, fullScreenLoading, serverError } = useSelector(selectInsuranceQuoteInterstitialState)
  const startingQuote = useGetStartingQuote(building, insuranceType)

  useEffect(() => {
    dispatch(setStartingQuote(isEmpty(startingQuote) ? null : startingQuote))
  }, [dispatch, startingQuote])

  const [premium, writingCompany] = useMemo(() => {
    if (quote && quote.insuranceType === insuranceType && quote.buildingId === building?.id) {
      return [quote.premium, quote.writingCompany]
    }

    if (startingQuote) {
      return [startingQuote.premium, startingQuote.writingCompany]
    }

    return [null, null]
  }, [quote, insuranceType, startingQuote, building?.id])

  const [started, setStarted] = useState(premium ? false : expanded)

  useEffect(() => {
    if (premium) return

    setStarted(expanded)

    if (!expanded) {
      setLoading(true)
    }
  }, [expanded])

  useEffect(() => {
    if (fullScreenLoading.visible) {
      setLoading(fullScreenLoading.visible)
    }

    if (!fullScreenLoading.visible && started) {
      setLoading(false)
    }
  }, [fullScreenLoading])

  const onInterstitialClose = () => {
    setStarted(false)
    setLoading(false)
    closeExpansion()
  }
  const onInterstitialOpen = () => {
    setStarted(true)
  }

  const renderQuoteMain = () => {
    if (serverError) {
      return <ServerError />
    }

    if (loading || !premium) {
      return <QuoteLoading />
    }

    return <QuoteResult premium={premium} />
  }

  const handleToggleInsuranceType = (on) => {
    setInsuranceType(on ? 'renters' : 'homeowners')
  }

  return (
    <div className={clsx('estimated-quote-result__wrapper', expanded && 'expanded')}>
      <div style={{ marginBottom: '18px' }}>
        <Switch
          offText="owner"
          onText="renter"
          onChange={handleToggleInsuranceType}
          on={defaultInsuranceType === 'renters'}
        />
      </div>
      <div className="estimated-quote-result">{renderQuoteMain()}</div>
      {writingCompany && expanded && !loading && (
        <div className="insurance-written-by dome-color-med-grey">Insurance underwritten by: {writingCompany}</div>
      )}
      <div style={{ marginTop: '18px' }}>
        <div className="dome-d-flex dome-justify-between dome-align-center">
          <div className="quote-logos">
            <div>
              <em>by</em>
              <img src={viaffinityLogo} style={{ width: '56px' }} />
            </div>
            <div>
              <em>from</em>
              <img src={chubbLogo} style={{ width: '60px' }} />
            </div>
          </div>
          <GetQuoteButton
            building={building}
            package_id={package_id}
            started={started}
            onInterstitialClose={onInterstitialClose}
            onInterstitialOpen={onInterstitialOpen}
            insuranceType={insuranceType}
          />
        </div>
      </div>
    </div>
  )
}

function ApplicationEntrypoint({ application_package, user, insurance_type }) {
  const { building, package_type, hashed_id: package_id, friendly_package_type } = application_package
  const [expanded, setExpanded] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentUser(user))
  }, [])

  const toggleExpand = () => setExpanded((expanded) => !expanded)

  return (
    <div className="insurance-application-entrypoint">
      <div className="dome-d-flex dome-justify-between">
        <div className="dome-p2 dome-text-w500 dome-color-dark-green">
          insurance for your {!!package_type ? friendly_package_type : "package's building"}
        </div>
        <span onClick={toggleExpand} style={{ cursor: 'pointer' }}>
          <i className={clsx('fa-solid', expanded ? 'fa-chevron-up' : 'fa-chevron-down')}></i>
        </span>
      </div>
      <ul className="insurance-intro-list">
        <li>matches property requirements</li>
        <li>personal info not needed for estimate</li>
        <li>policy auto-uploaded to library</li>
      </ul>
      <EstimatedQuoteResult
        building={building}
        package_id={package_id}
        expanded={expanded}
        closeExpansion={() => setExpanded(false)}
        insuranceType={insurance_type}
      />
    </div>
  )
}

export default (props) => (
  <Provider store={store}>
    <ApplicationEntrypoint {...props} />
  </Provider>
)
