import React, { createContext, useState } from "react";
import { VIEWS } from '../features/views/views'

export const viewContext = createContext(null)

export const ViewContextProvider = ({ threadsPerPage, messagesPerPage, owner_id, owner_class, children }) => {
  const [currentView, setCurrentView] = useState(VIEWS.LIST_VIEW)

  return (
    <viewContext.Provider value={{ currentView, setCurrentView, threadsPerPage, messagesPerPage, owner_id, unique_owner_id: owner_id }}>
      {children}
    </viewContext.Provider>
  )
}
