import React, { useState, useEffect } from "react"
import SelectTypeModal from "./select_type_modal"
import ConciergeModal from "./modal"

const Button = ({onClickStart, text, img, btnClasses}) => {
  const classes = ['request-a-quote']
    if (btnClasses) {
      const joinedClasses = Array.isArray(btnClasses) ? btnClasses.join(' ') : btnClasses
      classes.push(joinedClasses)
    }

  return (
    <button className={classes.join(' ')} onClick={onClickStart}>
      {img && <img src={img} />} {text}
    </button>
  )
}

const AllSteps = props => {
  const [step, setStep] = useState(0)
  const [type, setType] = useState(props.type ?? null)

  const closeFirst = t => {
    setType(t)
    setStep(t ? 2 : 0)
  }

  const onClickStart = () => {
    props.type ? setStep(2) : setStep(1)
  }

  return (
    <>
      <Button onClickStart={onClickStart} text={props.text} img={props.button_image} btnClasses={props.btn_classes} />

      {step == 1 && <SelectTypeModal onClose={closeFirst} type={props.type ? props.type: type} /> }
      {step == 2 && (
        <ConciergeModal
          type={props.type ? props.type : type}
          onClose={closeFirst}
          roles={props.roles}
          package_types={props.package_types}
          service_types={props.service_types}
          agent_types={props.agent_types}
        />
      ) }
    </>
  )
}

export default AllSteps
