import React, { useCallback, useMemo, useState } from "react"

const PreviousButton = ({ goPrevious, canGoBack }) => {
  return (
    <li>
      <button onClick={goPrevious} disabled={!canGoBack}>
        <i className="fa-regular fa-chevron-left"></i>
      </button>
    </li>
  )
}

const ForwardButton = ({ goForward, canGoForward }) => {
  return (
    <li>
      <button onClick={goForward} disabled={!canGoForward}>
        <i className="fa-regular fa-chevron-right"></i>
      </button>
    </li>
  )
}

const PageButtons = ({ pages, page, setPage }) => {
  return (
    <>
      {pages.map((pageNumber, index) => {
          if (pageNumber === '...') {
            return <li key={`ellipsis-${index}`}>{pageNumber}</li>
          }

          return (
            <li key={pageNumber}>
              <button
                className={pageNumber === page ? 'active' : undefined}
                onClick={() => setPage(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          )
        })}
    </>
  )

}

export function usePaginate({ start = 1, perPage = 5, total: initialTotal = null }) {
  const [page, setPage] = useState(start)
  const [total, setTotal] = useState(initialTotal)

  const lastPage = useMemo(() => {
    if (total === null) return null
    return Math.ceil(total / perPage)
  }, [total, perPage])

  const goPrevious = useCallback(() => {
    setPage((prev) => Math.max(prev - 1, 1))
  }, [])

  const goForward = useCallback(() => {
    setPage((prev) => (lastPage !== null ? Math.min(prev + 1, lastPage) : prev))
  }, [lastPage])

  const canGoBack = page > 1
  const canGoForward = lastPage !== null && page < lastPage

  const pages = useMemo(() => {
    if (lastPage === null || lastPage === 1) return []

    const result = []
    result.push(1) // Always show the first page

    if (page > 3) {
      result.push('...')
    }

    // Include the previous page if not on the first or second page
    if (page > 2) {
      result.push(page - 1)
    }

    // Include the current page if it's not the first or last
    if (page !== 1 && page !== lastPage) {
      result.push(page)
    }

    // Include the next page if not on the last or second-to-last page
    if (page < lastPage - 1) {
      result.push(page + 1)
    }

    if (page < lastPage - 2) {
      result.push('...')
    }

    result.push(lastPage) // Always show the last page

    return result
  }, [lastPage, page])

  const Component = useCallback(() => {
    if (lastPage < 1) return null

    return (
      <ul className="dome-pagination">
        <PreviousButton goPrevious={goPrevious} canGoBack={canGoBack} />
        <PageButtons pages={pages} page={page} setPage={setPage} />
        <ForwardButton goForward={goForward} canGoForward={canGoForward} />
      </ul>
    )
  }, [pages, page, goPrevious, goForward, canGoBack, canGoForward, lastPage])

  return { page, setPage, Component, setTotal }
}
