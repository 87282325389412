import React from 'react'

const QUERY_TIMEOUT = 300

class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.submerchant_options = []
    this.state.submerchant = this.props.submerchant

    this.state.editing = false

    this.monitorWindowClick = evt => {
      if (this.state.submerchant_options.length > 0)
        this.setState({submerchant_options: []}) 
    }
  }

  componentDidMount() {
    window.addEventListener("click", this.monitorWindowClick)
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.monitorWindowClick)
  }

  startEditing() {
    const focusOnQuery = () => this.refs.query ? this.refs.query.focus() : null
    this.setState({editing: true}, focusOnQuery)
  }

  updateQuery(query) {
    clearTimeout(this.query_timeout)
    this.setState({query})
    this.query_timeout = setTimeout(() => this.lookupSubmerchants(), QUERY_TIMEOUT)
  }

  lookupSubmerchants() {
    const url = `/sub_merchants/org/${this.props.organization_id}/autosuggest?q=${this.state.query}`
    const success = data => {
      this.setState({submerchant_options: data.sub_merchants || []})
    }

    $.ajax({url, success})
  }

  save(submerchant) {
    const old_id = this.state.submerchant ? this.state.submerchant.id : null
    const new_id = submerchant ? submerchant.id : null

    const state = {submerchant, submerchant_options: [], query: null, editing: false}
    this.setState(state, () => old_id != new_id ? this.persist() : null)
  }

  persist() {
    const url = "/sub_merchants/role"
    const data = { 
      owner_type: this.props.owner_type,
      owner_id: this.props.owner_id,
      role: this.props.role,
      sub_merchant_id: this.state.submerchant ? this.state.submerchant.id : null
      }

    $.ajax({
      url, type: this.state.submerchant ? "post" : "delete",
      data: hashToPostQueryString({sub_merchant_role: data})
    })
  }

  render() {
    return <div className="sub_merchant_roles-edit-component">
      <h5 className="title">{this.props.title}</h5>
      { this.state.editing ? this.renderAutosuggest() : this.renderSelected()}
    </div>
  }

  renderSelected() {
    const default_value = <em>default submerchant</em>

    return <div className="selected-submerchant">
      <div>
        <span>
          { this.state.submerchant ? this.state.submerchant.description : default_value }
        </span>
      </div>
      <button className="dome-btn dome-btn-base dome-btn-aqua-stroke" onClick={() => this.startEditing()}>
        change
      </button>
    </div>
  }


  renderAutosuggest() {
    const lookupOnFocus = () => this.state.query ? this.lookupSubmerchants() : null
    const disableHideMenu = e => e.stopPropagation()

    return <div className="submerchant-autosuggest">
      <div className="suggestion-area">
        <input ref="query" type="text" className="form-control" onClick={disableHideMenu} onChange={e => this.updateQuery(e.target.value)} onFocus={lookupOnFocus} />
        {this.renderDefaultButton()}
      </div>
      {this.renderOptions()}
    </div>
  }

  renderDefaultButton(opts = {}) {
    const classes = ["dome-btn", "dome-btn-base", "dome-btn-grey-stroke"]
    const disabled = opts.disabled
    if (disabled)
      classes.push("disabled")

    return <button className={classes.join(" ")} disabled={disabled} type="button" onClick={() => this.save(null)}>
      use default
    </button>
  }

  renderOptions() {
    if (this.state.submerchant_options.length == 0)
      return 

    return <div className="submerchant-options">
      { this.state.submerchant_options.map(submerchant => this.renderSubmerchant(submerchant))}
    </div>
  }

  renderSubmerchant(submerchant) {
    const click = () => 
      this.save(submerchant)

    return <div key={submerchant.id} onClick={click}>
      {submerchant.description}
    </div>
  }

}

export default Edit
