import React from 'react'
import ViaffinityDisclosureContent from './ViaffinityDisclosureContent'
import airPlane from '/app/assets/images/insurance/icons/airplane.svg'

export default function QuoteSentPage({ onClose }) {
  return (
    <>
      <div id="insurance-interstitial-quote-sent-page">
        <div className="container-wrapper">
          <div className="what-we-know">
            <div>
              <img src={airPlane} />
            </div>
            <div className="what-we-know-header">
              <h2 className="what-we-know-header__title dome-color-dark-green">you're almost there!</h2>
            </div>
            <div className="what-we-know-body">
              <div className="dome-h4 dome-color-dark-grey">what’s next</div>
              <ol>
                <li>
                  <p className="p2">
                    A Viaffinity insurance agent will contact you to finalize details of your policy, typically within 2 hours during normal business hours or within 24 hours at other times.
                  </p>
                </li>
                <li>
                  <p className="p2">
                    The agent will provide you with a final policy and Certificate of Insurance (C.O.I.).
                  </p>
                </li>
                <li>
                  <p className="p2">
                    If you have a Domecile account, a copy of the C.O.I. will be placed in your library.
                  </p>
                </li>
              </ol>
              <div className="quote-sent-close-btn-wrapper">
                <button className="dome-btn dome-btn-base dome-btn-grey-stroke" onClick={onClose}>
                  close
                </button>
              </div>
            </div>
          </div>
          <div className="viaffinity-disclosure">
            <div className="viaffinity-disclosure-content">
              <ViaffinityDisclosureContent />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
