import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { viewContext } from '../../context/viewContext'
import { userContext } from '../../context/userContext'
import { VIEWS } from './views'
import Header from '../../common/Header'
import { selectOwner, useCreateThreadMutation, useFetchParticipantsQuery, useGetInitialLoadQuery } from '../api/apiSlice'
import Autosuggest from 'components/utility/autosuggest'

const SelectAll = ({unselected, addAllToSelected, isFetching}) => {
  const unselectedGroups = useMemo(() => unselected.filter(item => !item.user), [unselected])
  return (
    <>
      {(isFetching || unselectedGroups.length > 0) && <button onClick={addAllToSelected} className='dome-btn dome-btn-link dome-btn-link-med-grey'>+ select all</button>}
    </>
  )
}

const SelectedRecipients = ({selectedRecipients, removeFromSelected}) => {
  return (
    <div className='dome-d-flex dome-align-center dome-gap6 dome-flex-wrap'>
      {selectedRecipients?.map(groupName => (
        <button
          key={groupName.value}
          onClick={() => removeFromSelected(groupName)}
          name={groupName.value}
          className='recipient dome-btn dome-btn-base-fit-content dome-btn-grey-stroke'
          style={{backgroundColor: 'var(--light-fill)'}}
        >
          {groupName.label} <i style={{pointerEvents: 'none'}} className='fal fa-xmark' />
        </button>
      ))}
    </div>
  )
}

const UnSelectedRecipients = ({ isFetching, unselected, addToSelected }) => {
  const unselectedGroups = useMemo(() => unselected.filter(item => !item.user), [unselected])

  const items = useMemo(() => {
    if (isFetching)
      return <i className='fal fa-spinner fa-spin-pulse' />

    const classes = 'recipient dome-btn dome-btn-base-fit-content dome-btn-grey-stroke'
    return unselectedGroups?.map(item => (
      <button key={item.value} onClick={() => addToSelected(item)} className={classes}>{item.label}</button>
    ))
  }, [unselectedGroups, isFetching, addToSelected])

  return (
    <div className='quick-add-container dome-d-flex dome-align-center dome-gap6'>
      {(isFetching || unselectedGroups.length !== 0) && <div className='dome-color-green-gradient'><i className='fal fa-sparkle' /> quick add</div>}
      <div className='dome-d-flex dome-align-center dome-gap6'>
        {items}
      </div>
    </div>
  )
}

function usePopulateAllowedSelectors({ page }) {
  const { unique_owner_id } = useContext(viewContext)
  const { data, isFetching } = useFetchParticipantsQuery({ unique_owner_id }, {refetchOnMountOrArgChange: true})
  const { owner } = useGetInitialLoadQuery({unique_owner_ids: [unique_owner_id]}, {
    selectFromResult: (result) => ({
      owner: selectOwner(result, unique_owner_id)
    })
  })

  const allowedGroups = data?.my_allowed_groups
  const allowedUsers = data?.my_allowed_users
  const [unselected, setUnselected] = useState([{value: '', label: ''}])

  useEffect(() => {
    const unselected = []

    if (allowedGroups)
      unselected.push( allowedGroups.map(value => ({ value, label: owner.roles[value].display_select })) )
    if (allowedUsers)
      unselected.push( allowedUsers.map(user => ({ value: user.unique_id, label: user.full_name, user: true, role: user.role }) ) )

    setUnselected( unselected.flat() )
  }, [allowedGroups, allowedUsers, owner])

  return { unselected, setUnselected, isFetching }
}

const findMatchingParticipant = (list, participant) => {
  if (typeof participant != 'string')
    return participant

  return list.filter(item => item.value == participant)[0]
}

const RecipientSelector = ({ selectedRecipients, setSelectedRecipients, page }) => {
  const { unselected, setUnselected, isFetching } = usePopulateAllowedSelectors(page)

  const addToSelected = useCallback((participant) => {
    participant = findMatchingParticipant(unselected, participant)
    if (!participant?.value) return

    setUnselected(unselected.filter(item => item != participant))
    setSelectedRecipients([...selectedRecipients, participant])
  }, [unselected, selectedRecipients])

  const removeFromSelected = useCallback(participant => {
    participant = findMatchingParticipant(selectedRecipients, participant)
    if (!participant?.value) return

    setSelectedRecipients(selectedRecipients?.filter(item => item != participant))
    setUnselected([...unselected, participant])
  }, [unselected, selectedRecipients])

  const addAllToSelected = useCallback(() => {
    if (!unselected.length) return
    const groups = unselected.filter(r => !r.user)
    const users = unselected.filter(r => r.user)

    setUnselected([...users])
    setSelectedRecipients([...selectedRecipients, ...groups])
  }, [unselected, selectedRecipients])

  return (
    <div className='dome-d-flex dome-flex-column dome-gap18'>
      <div className='dome-d-flex dome-align-baseline dome-justify-start dome-gap12'>
        <span>to:</span>
        <Autosuggest options={unselected.map(item => [ item.value, item.label])} onSelect={value => addToSelected(value)} searchByValue hideOnSelect />
        <SelectedRecipients selectedRecipients={selectedRecipients} removeFromSelected={removeFromSelected} />
        <SelectAll unselected={unselected} addAllToSelected={addAllToSelected} isFetching={isFetching} />
      </div>
      <UnSelectedRecipients isFetching={isFetching} unselected={unselected} addToSelected={addToSelected} />
    </div>
  )
}

const ThreadComposer = ({ setInputText, selectedRecipients, setSelectedRecipients, page }) => {
  const handleChange = (e) => {
    setInputText(e.target.value)
  }
  return (
    <div className='thread-composer-body dome-d-flex dome-flex-column'>
      <RecipientSelector selectedRecipients={selectedRecipients} setSelectedRecipients={setSelectedRecipients} page={page} />
      <textarea placeholder='type your message here...' name="messagebox" id="messagebox" onChange={handleChange}></textarea>
    </div>
  )
}

const ThreadActionFooter = ({ inputText, handleSend, isLoading, isSuccess, selectedRecipients }) => {
  const { setCurrentView } = useContext(viewContext)

  const isDisabled = selectedRecipients.length == 0 || !inputText && inputText.length === 0

  const classes = ['dome-btn', 'dome-btn-base', 'dome-btn-base-fit-content']
  classes.push(isDisabled ? 'dome-btn-disabled' : 'dome-btn-go-green')

  const buttonIconClasses = []
  buttonIconClasses.push(isLoading ? 'fal fa-spinner fa-spin-pulse' : 'fal fa-paper-plane-top')

  const handleCancel = () => setCurrentView(VIEWS.LIST_VIEW)


  useEffect(() => {
    if (isSuccess) {
      setCurrentView(VIEWS.LIST_VIEW)
    }
  }, [isSuccess])

  return (
    <div className='thread-action-footer dome-d-flex dome-align-center dome-justify-between'>
      <button onClick={handleCancel} className="dome-btn dome-btn-link dome-btn-link-cancel">
        cancel
      </button>
      <button onClick={handleSend} className={classes.join(' ')}>
        <i className={buttonIconClasses}/>
      </button>
    </div>
  )
}

const NewThreadView = ({ page }) => {
  const { currentView, unique_owner_id, threadsPerPage } = useContext(viewContext)
  const [inputText, setInputText] = useState('')
  const [selectedRecipients, setSelectedRecipients] = useState([])
  const [createThread, { isLoading, isSuccess }] = useCreateThreadMutation()

  const handleSend = useCallback(() => {
    if (selectedRecipients.length == 0 || inputText.length == 0) return

    const newMessage = {
      message: inputText,
      unique_owner_id,
      participants: selectedRecipients.map(r => r.value),
      per_page: threadsPerPage,
      page: 1,
    }

    createThread(newMessage)
  }, [inputText, selectedRecipients])

  if (currentView !== VIEWS.NEW_MESSAGE_VIEW) return

  return (
    <div className='dome-d-flex dome-flex-column dome-gap12'>
      <Header headerTitle='new message' />
      <ThreadComposer setInputText={setInputText} selectedRecipients={selectedRecipients} setSelectedRecipients={setSelectedRecipients} page={page} />
      <ThreadActionFooter inputText={inputText} handleSend={handleSend} isLoading={isLoading} isSuccess={isSuccess} selectedRecipients={selectedRecipients} />
    </div>
  )
}

export default NewThreadView
