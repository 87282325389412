import React, { useState } from 'react'
import ENotaryIntroModal from "./ENotaryIntroModal"
import ENotaryModal from "./ENotaryModal"
import ENotaryEndingModal from "./ENotaryEndingModal"
import { Portal } from '../utility/portal'

//TO-DO: Refactor, please.

export default function steps_for_modals(props) {
  const [step, setStep] = useState(0)
  const [passedAddress, setPassedAddress] = useState('')
  const [passedUnit, setPassedUnit] = useState(0)

  const handleClick = () => {
    if (step !== 0) return
    setStep(1)
  }

  const renderButton = () => {
    return (
      <button id="enotary-request-btn" className='request-notary mobile-sizes btn-base btn-dark-green' onClick={() => setStep(1)}>
        {props.image ? <img src={props.image} style={{ width: "14px", marginBottom: "3px" }} /> : null} {props.text}
      </button>
    )
  }

  const renderLink = () => {
    if (!props.tab) {
      return (
        <button
          className='get-started-button btn-link primary-link'
          onClick={() => setStep(1)}
        >
          {props.text} <i className={props.icon_class}></i>
        </button>
      )
    }
    else {
      return (
        <button
          className='e-notary-tab'
          onClick={handleClick}
        >
          {props.text}
        </button>
      )
    }

  }

  function ensureModalIsClosed() {
    setStep(0)
  }

  function stepToEndAndPass(address, unit) {
    setPassedAddress(address);
    setPassedUnit(unit);
    setStep(3);
  }

  return (
    <>
      {props.button ? renderButton() : renderLink()}
      <Portal>
        {step == 1 || props?.step == 1 ? <ENotaryIntroModal step={step} setStep={setStep} onClose={ensureModalIsClosed} /> : null}
        {step == 2 ? (
          <ENotaryModal
            {...props}
            setStep={setStep}
            step={step}
            stepToEndAndPass={stepToEndAndPass}
            onClose={ensureModalIsClosed}
          />
        ) : step == 3 ? <ENotaryEndingModal step={setStep} setStep={setStep} onClose={ensureModalIsClosed}
          passedAddress={passedAddress} passedUnit={passedUnit} /> : null}
      </Portal>
    </>
  )
}
