import React, { forwardRef, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { userContext } from '../../context/userContext'
import {
  apiSlice,
  selectLastMessageId,
  selectLastSeenId,
  selectMessageAuthors,
  selectOwner,
  selectParticipants,
  selectRoles,
  selectThreadById,
  sortedMessages,
  useAddMessageMutation,
  useFetchMessagesQuery,
  useUpdateLastReadMutation
} from '../api/apiSlice'
import { format, isSameDay } from 'date-fns'
import { findIndex, first, last } from 'lodash'
import InfiniteScrollLoader from '../../common/InfiniteScrollLoader'
import { viewContext } from '../../context/viewContext'
import { useDisplayParticipants } from '../../common/useDisplayParticipants'

function determineMessageType(participants) {
  if (!participants || participants.length === 0) return null

  if (participants.some(p => p.role !== null)) return 'group'

  if (participants.length === 2) return 'individual'

  return 'group'
}

const useInfiniteScroll = (ref, isFetching, page, pagination, isError, setPage, setPrevScrollHeight, localPage, setLocalPage) => {

  const loadMore = useCallback(() => {
    if (localPage === pagination?.total_pages) return

    if (!isFetching && !isError) {
      setPrevScrollHeight(ref.current.scrollHeight)
      setLocalPage(localPage + 1)
    }
  }, [page, pagination?.total_pages, isFetching, isError, ref, localPage])

  useEffect(() => {
    const element = ref.current

    const handleScroll = () => {
      if (element.scrollTop === 0 && !isFetching) {
        loadMore()
      }
    }

    element.addEventListener('scroll', handleScroll)
    return () => element.removeEventListener('scroll', handleScroll)
  }, [isFetching, loadMore, ref])
}

const useScrollPosition = (ref, isFetching, prevScrollHeight, dailyMessages) => {
  useEffect(() => {
    const element = ref.current

    if (!isFetching && prevScrollHeight) {
      const newScrollHeight = element.scrollHeight
      element.scrollTop = newScrollHeight - prevScrollHeight
    }
  }, [isFetching, prevScrollHeight, dailyMessages, ref])
}

const useDrawerScroll = (ref, isSuccess, drawerOpen) => {
  useEffect(() => {
    if (ref.current && isSuccess && drawerOpen) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [drawerOpen, isSuccess, ref])
}


function useDailyMessages(thread_id, page) {
  const { messagesPerPage } = useContext(viewContext)
  const { messagesList, messageThreads, lastSeenId, lastMessageId, isSuccess, isFetching, isLoading, isError, pagination } = useFetchMessagesQuery({ thread_id, page, per_page: messagesPerPage }, {
      selectFromResult: (result) => ({
          ...result,
          messageThreads: result.data?.message_threads,
          lastSeenId: selectLastSeenId(result, thread_id),
          lastMessageId: selectLastMessageId(result, thread_id),
          messagesList: sortedMessages(result, thread_id) || [],
          pagination: result.data?.pagination
      }),
      skip: !thread_id
    }
  )

  const dailyMessages = useMemo(() => {
    const dailyMessages = []
    let currentDate = new Date(0)

    for (let message of messagesList) {
      const messageDate = new Date(Date.parse(message.posted_at))

      if (!isSameDay(currentDate, messageDate)) {
        currentDate = messageDate
        dailyMessages.push({ date: currentDate, messages: [] })
      }

      last(dailyMessages).messages.push(message)
    }

    return dailyMessages
  }, [messagesList])

  return {
    dailyMessages,
    isSuccess,
    isFetching,
    isLoading,
    isError,
    pagination,
    messageThreads,
    lastSeenId,
    lastMessageId
  }
}

function useFirstUnseenMessage(thread_id, page) {
  const { messagesPerPage } = useContext(viewContext)
  const { messages, last_seen_id } = useFetchMessagesQuery({ thread_id, page, per_page: messagesPerPage }, {
    selectFromResult: (result) => ({
      messages: sortedMessages(result, thread_id) || [],
      last_seen_id: selectLastSeenId(result, thread_id)
    }),
    skip: !thread_id
  })


  return useMemo(() => {
    if (!last_seen_id) return first(messages)

    const lastSeen = messages.findIndex(message => message.id == last_seen_id)

    return messages[lastSeen + 1]
  }, [messages, last_seen_id])
}

function showNewLabel(index, lastSeenIndex) {
  if (index === 0 && lastSeenIndex === null) {
    return true
  }

  if (index > 0 && index - 1 === lastSeenIndex) {
    return true
  }

  return false
}

const ViewOnlyBanner = () => {
  return (
    <div className='view-only-banner dome-p2 dome-d-flex dome-align-center dome-justify-center dome-gap3'>
      <span className='dome-color-dark-green'><i className='fal fa-eye'></i> view only |</span><span className='dome-color-dark-grey'>This thread is view only.</span>
    </div>
  )
}

const ChatHeader = ({ onClose, activeThreadId, page }) => {
  const { messagesPerPage, owner_id } = useContext(viewContext)
  const { participantList, users, owner, canPost, lastMessageId, isFetching } = useFetchMessagesQuery({ thread_id: activeThreadId, per_page: messagesPerPage, page }, {
    selectFromResult: (result) => ({
      ...result,
      participantList: selectParticipants(result, activeThreadId),
      users: result.data?.users,
      owner: selectOwner(result, owner_id),
      canPost: selectThreadById(result, activeThreadId)?.can_post || false,
      lastMessageId: selectLastMessageId(result, activeThreadId)
    }),
    skip: !activeThreadId
  })
  const participants = useDisplayParticipants(participantList, users, owner)
  const cantPost = !isFetching && !canPost && activeThreadId
  const title = useMemo(() => {
    const messageType = determineMessageType(participantList)
    return messageType ? `${messageType} message` : ''
  }, [participantList, determineMessageType])

  const handleClose = useCallback(() => {
    onClose({thread_id: activeThreadId, message_id: lastMessageId, per_page: messagesPerPage})
  }, [onClose, activeThreadId, lastMessageId, messagesPerPage])

  return (
    <div className='chat-header' style={{marginBottom: cantPost ? '37px' : '0'}}>
      {cantPost && <ViewOnlyBanner />}
      <div className='dome-d-flex dome-flex-column dome-gap12 dome-align-start'>
        <button onClick={handleClose} className='dome-btn dome-btn-link dome-btn-link-back'>back</button>
        <div>
          <div className='dome-p1'>{title}</div>
          <div className='dome-p3 dome-color-med-grey'>{!isFetching && `${participants}${!canPost ? '' : ', You'}`}</div>
        </div>
      </div>
    </div>
  )
}

const ChatMessageInput = forwardRef(({ drawerOpen, onClick, activeThreadId, page }, ref) => {
  const { unique_owner_id,  messagesPerPage } = useContext(viewContext)
  const { canPost } = useFetchMessagesQuery({ thread_id: activeThreadId, per_page: messagesPerPage, page }, {
    selectFromResult: (result) => ({canPost: selectThreadById(result, activeThreadId)?.can_post || false}),
    skip: !activeThreadId
  })
  const [value, setValue] = useState('')
  const hasInput = value && value.length > 0 && value.trim().length
  const disabled = !canPost || !hasInput
  const buttonClasses = ['send-button','dome-btn', 'dome-btn-base-fit-content']
  buttonClasses.push(disabled ? 'dome-btn-disabled' : 'dome-btn-go-green')

  const handleChange = (e) => setValue(e.target.value)
  const handleClick = () => {
    if (disabled) return

    onClick(value)
    setValue('')
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick()
    }
  }

  useEffect(() => {
    if (!drawerOpen) {
      setValue('')
    }
  }, [drawerOpen])

  return (
    <div className='chat-message-input-container'>
      <div className="dome-d-flex dome-align-center dome-gap6">
        <input
          ref={ref}
          type="text"
          value={value}
          placeholder='type your reply here...'
          className='chat-input dome-rounded-border dome-full-width'
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={!canPost}
        />
        <button className={buttonClasses.join(' ')} onClick={handleClick}><i className='fal fa-send' /></button>
      </div>
    </div>
  )
})

const ChatBubble = ({ message }) => {
  const date = new Date(message.posted_at)
  const formattedTimestamp = format(date, 'hh:mm aaa, MM/dd/yy')

  return (
    <div className='chat-bubble dome-d-flex dome-flex-column dome-justify-between dome-gap12 dome-rounded-border dome-light-shadow dome-bg-white'>
      <div className="message-content">
        {message.body}
      </div>
      <div className='dome-p3 dome-color-med-grey'>
        {formattedTimestamp}
      </div>
    </div>
  )
}

const MessageRow = ({messageUser, hideConsecutiveName, isCurrentUserMessage, roleDisplay, children}) => {

  return (
    <div className='message-row'>
      {(!isCurrentUserMessage && !hideConsecutiveName) && <div className='name-container dome-p3 dome-text-w500 dome-color-darkest-grey'>{messageUser.name} <span className='role-display dome-color-med-grey'>{roleDisplay}</span></div>}
      <div className={`chat-bubble-container dome-d-flex ${isCurrentUserMessage ? 'dome-justify-end' : ''}`}>
        {children}
      </div>
    </div>
  )
}

const NewMessageDivider = () => {
  return (
    <div className='new-message-divider'>
      <span className='dome-color-aqua dome-p3 dome-text-w500'>new messages</span>
    </div>
  )
}

const MessageDay = ({ date }) => {
  return (
    <div>friday i think</div>
  )
}

const MessageBlock = ({ message, hideConsecutiveName, messageUser, isCurrentUserMessage, roleDisplay, page }) => {
  const firstUnseen = useFirstUnseenMessage(message?.message_thread_id, page)
  const isFirstUnseen = message?.id == firstUnseen?.id

  return (
    <>
      { isFirstUnseen ? <NewMessageDivider /> : null }
      <MessageRow hideConsecutiveName={hideConsecutiveName} messageUser={messageUser} isCurrentUserMessage={isCurrentUserMessage} roleDisplay={roleDisplay} >
        <ChatBubble message={message} />
      </MessageRow>
    </>
  )
}

const DayOfMessages = ({ date, messages, activeThreadId, page }) => {
  const { owner_id, unique_owner_id, messagesPerPage } = useContext(viewContext)
  const { currentUser} = useContext(userContext)
  const { messageAuthors, roles, users, isLoading } = useFetchMessagesQuery({ thread_id: activeThreadId, page, per_page: messagesPerPage }, {
    selectFromResult: (result) => ({
      ...result,
      messageAuthors: selectMessageAuthors(result, activeThreadId) || {},
      roles: selectRoles(result, owner_id) || {},
      users: result.data?.users
    }),
    skip: !activeThreadId
  })

  if (isLoading || !users || !messageAuthors || !roles) return

  return (
    <div className="day-block">
      { date && <MessageDay date={date} /> }
      { messages.map((message, index) => {
        const hideConsecutiveName = index !== 0 && messages[index - 1]?.posted_by === message?.posted_by
        const messageUser = users && users[message?.posted_by]
        const isCurrentUserMessage = currentUser == messageUser?.id
        const messageAuthorType = messageAuthors && messageAuthors[message.posted_by]
        const roleDisplay = roles[messageAuthorType]?.display_singular

        return (
          <MessageBlock
            key={message.id}
            message={message}
            roleDisplay={roleDisplay}
            hideConsecutiveName={hideConsecutiveName}
            messageUser={messageUser}
            isCurrentUserMessage={isCurrentUserMessage}
            page={page}
          />
        )
      }) }
    </div>
  )
}

const ChatMessagesList = forwardRef(({ activeThreadId, drawerOpen, setShowArrow, page, setPage, localPage, setLocalPage }, ref) => {
  const initialScrollHeightRef = useRef(null)
  const [prevScrollHeight, setPrevScrollHeight] = useState(0)
  const { currentUser } = useContext(userContext)
  const { dailyMessages, messageThreads, lastSeenId, lastMessageId, pagination, isSuccess, isFetching, isLoading, isError } = useDailyMessages(activeThreadId, page)
  useInfiniteScroll(ref, isFetching, page, pagination, isError, setPage, setPrevScrollHeight, localPage, setLocalPage)
  useScrollPosition(ref, isFetching, prevScrollHeight, dailyMessages)
  useDrawerScroll(ref, isSuccess, drawerOpen)


  useEffect(() => {
    if (!drawerOpen || !isSuccess || !ref.current) return
    const element = ref.current

    if (initialScrollHeightRef.current === null) {
      initialScrollHeightRef.current = element.scrollHeight
    }

    const handleScroll = () => {
      const isScrolledToBottom = element.scrollTop + element.clientHeight >= element.scrollHeight - 1

      if (!isScrolledToBottom && element.scrollHeight > initialScrollHeightRef.current) {
        setShowArrow(true)
      } else if (isScrolledToBottom) {
        setShowArrow(false)
      }
    }

    element.addEventListener('scroll', handleScroll)
    return () => element.removeEventListener('scroll', handleScroll)
  }, [ref, drawerOpen, isSuccess])

  return (
    <>
      {isFetching && <InfiniteScrollLoader style={{position: 'absolute', top: '12rem', zIndex: '2', left: '50%'}}/>}
      <div ref={ref} className='dome-scrollable dome-relative'>
        <div className='chat-message-list-container dome-d-flex dome-flex-column dome-justify-end dome-bg-fill'>
          { pagination?.total_pages === localPage && <div className='dome-color-med-grey dome-p3' style={{textAlign: 'center', padding: '0 12px'}}>This is the beginning.</div> }
          {dailyMessages?.map((day, index) => {
            // date = null, disabling message days for now
            return <DayOfMessages
              key={day.date}
              date={null}
              messages={day.messages}
              activeThreadId={activeThreadId}
              page={page}
            />
          })}
        </div>
      </div>
    </>
  )
})


const ChatScrollArrow = ({ onScrollToBottom, showArrow }) => {
  return (
    <div onClick={onScrollToBottom} className='scroll-to-top-arrow dome-bg-white dome-absolute dome-d-flex dome-align-center dome-justify-center dome-light-shadow' style={{opacity: showArrow ? 1 : 0, pointerEvents: showArrow ? 'auto' : 'none'}}>
      <i style={{fontSize: '22px'}} className='far fa-arrow-down dome-color-aqua' />
    </div>
  )
}

const Backdrop = ({ onClose, activeThreadId, page }) => {
  const { messagesPerPage } = useContext(viewContext)
  const { lastMessageId } = useFetchMessagesQuery({ thread_id: activeThreadId, per_page: messagesPerPage, page }, {
    selectFromResult: (result) => ({
      lastMessageId: selectLastMessageId(result, activeThreadId)
    }),
    skip: !activeThreadId
  })

  const handleClose = useCallback(() => {
    onClose({thread_id: activeThreadId, message_id: lastMessageId, per_page: messagesPerPage})
  }, [onClose, activeThreadId, lastMessageId, messagesPerPage])

  return (
    <div onClick={handleClose} className="drawer-backdrop"></div>
  )
}

const ChatMain = forwardRef(({ onClose, activeThreadId, drawerOpen, setShowArrow, page, setPage }, ref) => {
  const [localPage, setLocalPage] = useState(1)
  useEffect(() => {
    setPage(localPage)
  }, [localPage])

  useEffect(() => {
    if (activeThreadId) {
      setPage(1)
    }
  }, [activeThreadId])

  return (
    <>
      <ChatHeader onClose={onClose} activeThreadId={activeThreadId} page={localPage} />
      <ChatMessagesList
        ref={ref}
        drawerOpen={drawerOpen}
        activeThreadId={activeThreadId}
        setShowArrow={setShowArrow}
        localPage={localPage}
        setLocalPage={setLocalPage}
        page={page}
        setPage={setPage}
      />
    </>
  )
})

const MessengerDrawer = ({ activeThreadId, drawerOpen, onClose, shouldRefetch, setShouldRefetch, threadPage }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [showArrow, setShowArrow] = useState(false)
  const [updateLastRead] = useUpdateLastReadMutation()
  const [addMessage] = useAddMessageMutation()
  const scrollableContainerRef = useRef(null)
  const inputRef = useRef(null)
  const { unique_owner_id, messagesPerPage, threadsPerPage } = useContext(viewContext)
  const { lastSeenId, lastMessageId } = useFetchMessagesQuery({ thread_id: activeThreadId, per_page: messagesPerPage, page }, {
    selectFromResult: (result) => ({
      lastSeenId: selectLastSeenId(result, activeThreadId),
      lastMessageId: selectLastMessageId(result, activeThreadId)
    }),
    skip: !activeThreadId
  })


  const handleClose = useCallback((params) => {
    if (lastSeenId !== lastMessageId && !shouldRefetch) {
      updateLastRead(params)
      dispatch(apiSlice.endpoints.getInitialLoad.initiate({ unique_owner_ids: [unique_owner_id], per_page: threadsPerPage, page: threadPage }, { subscribe: false, forceRefetch: true }))
    }

    onClose()
  }, [onClose, dispatch, unique_owner_id, threadsPerPage, threadPage, lastSeenId, lastMessageId])

  const handleSend = useCallback((value) => {
    const newMessage = {
      thread_id: activeThreadId,
      message: value,
      unique_owner_id,
      page,
      per_page: messagesPerPage
    }

    addMessage(newMessage)
    setShouldRefetch(true)
    setPage(1) // when sending a new message, make sure to get latest messages
  }, [activeThreadId, unique_owner_id, page, messagesPerPage])

  const scrollToBottom = useCallback(() => {
    const element = scrollableContainerRef.current

    if (element && element.scrollTop >= 0) {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth'
      })
    }
  }, [])

  const handleTransitionEnd = () => inputRef.current.focus( {preventScroll: true })

  return (
    <div className={`messenger-drawer-container ${drawerOpen ? 'open' : ''}`} onTransitionEnd={handleTransitionEnd}>
      <div className="dome-relative" style={{height: '100%'}}>
        <Backdrop onClose={handleClose} activeThreadId={activeThreadId} page={page} />
        <div className="messenger-drawer dome-bg-white dome-full-width dome-relative">
          <ChatMain
            ref={scrollableContainerRef}
            drawerOpen={drawerOpen}
            activeThreadId={activeThreadId}
            setShowArrow={setShowArrow}
            page={page}
            setPage={setPage}
            onClose={handleClose}
          />
          <ChatMessageInput ref={inputRef} drawerOpen={drawerOpen} onClick={handleSend} activeThreadId={activeThreadId} page={page} />
          <ChatScrollArrow onScrollToBottom={scrollToBottom} showArrow={showArrow} />
        </div>
      </div>
    </div>
  )
}

export default MessengerDrawer
