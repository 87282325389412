import React, { useEffect } from 'react'

import { useWatchableRef } from 'lib/hooks'

export default function ConfirmBackToPreviousStepModal({ open, onClose, onSubmit }) {
  const modalRef = useWatchableRef(null)

  useEffect(() => {
    if (!modalRef.current) return

    const handleClose = (_e) => onClose()
    const localRefValue = modalRef.current

    $(localRefValue).on('hidden.bs.modal', handleClose)

    return () => $(localRefValue).off('hidden.bs.modal', handleClose)
  }, [onClose, modalRef.version])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) {
      $(modalRef.current).modal('show')
    } else {
      $(modalRef.current).modal('hide')
    }
  }, [open, modalRef.version])

  return (
    <div ref={modalRef.ref} className="modal fade insurance-confirm-back-to-previous-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-header">
            <i className="fa-thin fa-exclamation-circle dome-color-red-alert" style={{ fontSize: '46px' }} />
          </div>
          <div className="modal-body">
            <div className="dome-h2 dome-color-dark-grey">are you sure?</div>
            <div className="dome-p2">Upon closing, all information provided will be lost</div>
          </div>
          <div className="modal-footer">
            <button
              className="dome-btn dome-btn-link pull-left dome-color-med-grey"
              data-dismiss="modal"
              onClick={onClose}
            >
              stay on this page
            </button>
            <button type="submit" className="dome-btn dome-btn-base dome-btn-aqua-stroke pull-right" onClick={onSubmit}>
              yes, edit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
