import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { addCategory, selectBuildingEditResidentPayments, dragEnd } from "../store/slice";
import BuildingServiceCategory from "./BuildingServiceCategory";
import RemoveServiceModal from "./RemoveServiceModal";
import RemoveCategoryModal from "./RemoveCategoryModal";
import { useRemoveServiceModal, useRemoveCategoryModal } from "../hooks";

export default function BuildingEditServices(props) {
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);

  const handleAddCategory = () => dispatch(addCategory());

  const { building, categories, services, category_services } = useSelector(selectBuildingEditResidentPayments);

  const { removeServiceModalVisible, onServiceModalRemove, onServiceModalCancel } = useRemoveServiceModal();

  const { removeCategoryModalVisible, onCategoryModalRemove, onCategoryModalCancel } = useRemoveCategoryModal();

  const onDragEnd = (result) => {
    dispatch(dragEnd({ result }));
    setIsDragging(false);
  };

  /**
   * https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/responders.md#ondragstart
   **/
  const onDragStart = () => {
    setIsDragging(true);
  };

  if (!building) return null;

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId="all-categories" direction="vertical" type="categories">
          {(provided) => {
            return (
              <div className="building-edit-services-container" {...provided.droppableProps} ref={provided.innerRef}>
                {categories.map((category, index) => (
                  <Draggable key={category.id} draggableId={`category-${category.id}`} index={index}>
                    {(provided) => {
                      return (
                        <BuildingServiceCategory
                          dragProvided={provided}
                          category={category}
                          index={index}
                          sub_merchant_dropdown={building.sub_merchant_dropdown_with_default || []}
                          services={(category_services[category.id] || []).map((serviceId) => services[serviceId])}
                          isLast={index === categories.length - 1}
                          isDragging={isDragging}
                        />
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <div className="new-fee-category">
        <span onClick={handleAddCategory}>+ New Fee Category</span>
      </div>
      <RemoveServiceModal
        open={removeServiceModalVisible}
        onSubmit={onServiceModalRemove}
        onClose={onServiceModalCancel}
      />
      <RemoveCategoryModal
        open={removeCategoryModalVisible}
        onSubmit={onCategoryModalRemove}
        onClose={onCategoryModalCancel}
      />
    </>
  );
}
